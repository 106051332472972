<template>
  <section
    v-if="filter"
    class="additional-filters"
    @keyup.enter="searchByEnterKey"
  >
    <div class="property-type">
      <Typography
        variant="title-medium"
        color="highlight-default"
      >
        Tipo de imóvel
      </Typography>
      <div class="flex justify-start gap-3">
        <Button
          variant="secondaryXs"
          @click="filter.propertyType = PROPERTY_TYPE.APARTMENT"
          :class="[filter.propertyType === PROPERTY_TYPE.APARTMENT && 'button-active']"
        >
          Apartamentos
        </Button>
        <Button
          variant="secondaryXs"
          @click="filter.propertyType = PROPERTY_TYPE.PENTHOUSE"
          :class="[filter.propertyType === PROPERTY_TYPE.PENTHOUSE && 'button-active']"
        >
          Coberturas
        </Button>
        <Button
          variant="secondaryXs"
          @click="filter.propertyType = PROPERTY_TYPE.HOUSE"
          :class="[filter.propertyType === PROPERTY_TYPE.HOUSE && 'button-active']"
        >
          Casas
        </Button>
      </div>
    </div>
    <div class="property-price flex w-full justify-start gap-4">
      <div class="w-1/2">
        <div class="mb-3">
          <Typography
          variant="label-small-bold"
          color="highlight-default"
        >
          Valor
          <Typography
            variant="label-small"
            color="highlight-default"
          >
            mínimo
          </Typography>
        </Typography>
        </div>
        <v-text-field
          test-id="price-min"
          :value="filter.priceMin ? formatStringToThousand(filter.priceMin) : ''"
          :rules="[minMaxPriceIsConsistent]"
          name="valueMin"
          label="R$"
          outlined
          @keydown="preventNonInteger($event)"
          @input="validatePrices('min', $event)"
        />
        <span
          test-id="price-error"
          v-if="!minMaxPriceIsConsistent"
          class="value-error flex mt-1"
        >
          <alert-icon class="mt-1 mx-1" />
          Valor deve ser inferior ao máximo
        </span>
      </div>
      <div class="w-1/2">
        <div class="mb-3">
          <Typography
          variant="label-small-bold"
          color="highlight-default"
        >
          Valor
          <Typography
            variant="label-small"
            color="highlight-default"
          >
            máximo
          </Typography>
        </Typography>
        </div>
        <v-text-field
          test-id="price-max"
          :value="filter.priceMax ? formatStringToThousand(filter.priceMax) : ''"
          :rules="[minMaxPriceIsConsistent]"
          name="valueMax"
          label="R$"
          outlined
          @keydown="preventNonInteger($event)"
          @input="validatePrices('max', $event)"
        />
      </div>
    </div>
    <div class="property-area flex justify-start gap-4">
      <div class="w-1/2">
        <div class="mb-3">
          <Typography
          variant="label-small-bold"
          color="highlight-default"
        >
          Área
          {{filter.propertyType === PROPERTY_TYPE.HOUSE ? "construída" : "útil" }}
          <Typography
            variant="label-small"
            color="highlight-default"
          >
            mínima
          </Typography>
        </Typography>
        </div>
        <v-text-field
          test-id="area-min"
          :value="filter.areaRange[0]"
          :rules="[minMaxAreaIsConsistent]"
          :min="0"
          :max="1000000"
          label="m²"
          outlined
          @keydown="preventNonInteger($event)"
          @input="$set(filter.areaRange, 0, $event ? getInteger($event) : null)"
        />
        <span
          test-id="area-error"
          v-if="!minMaxAreaIsConsistent"
          class="value-error flex mt-1"
        >
          <alert-icon class="mt-1 mx-1" />
          Valor deve ser inferior ao máximo
        </span>
      </div>
      <div class="w-1/2">
        <div class="mb-3">
          <Typography
          variant="label-small-bold"
          color="highlight-default"
        >
          Área
          {{filter.propertyType === PROPERTY_TYPE.HOUSE ? "construída" : "útil" }}
          <Typography
            variant="label-small"
            color="highlight-default"
          >
            máxima
          </Typography>
        </Typography>
        </div>
        <v-text-field
          test-id="area-max"
          :value="filter.areaRange[1]"
          :rules="[minMaxAreaIsConsistent]"
          :min="0"
          :max="1000000"
          label="m²"
          outlined
          @keydown="preventNonInteger($event)"
          @input="$set(filter.areaRange, 1, $event ? getInteger($event) : null)"
        />
      </div>
    </div>
    <div
      class="more-filters"
      @click="showMoreFilters = !showMoreFilters"
    >
      <Typography
        variant="header-large"
        color="highlight-default"
      >
        Outros filtros
      </Typography>

      <div class="more-filters-separator" />
      <span
        class="transform"
        :class="{'rotate-180': showMoreFilters}"
      >
        <img src="@/assets/images/arrow_drop_down_circle.svg" >
      </span>
    </div>
    <div v-if="showMoreFilters" class="width-other-filters" >
      <div class="property-status search-options flex flex-column gap-2">
        <Typography
          variant="title-medium"
          color="highlight-default"
        >
          Fase
        </Typography>
        <div class="flex align-center gap-2">
          <Button
            test-id="status-button"
            variant="secondaryXs"
            v-for="status in Object.values(PROPERTY_STATUS)"
            :key="status.value"
            @click="togglePropertyStatus(status.value)"
            :class="[filter.propertyStatus.includes(status.value) ? 'button-active' : 'button-default']"
          >
            {{ status.label }}
          </Button>
        </div>
      </div>
      
      <div class="property-dorms search-options checkbox flex flex-column gap-2">
        <Typography
          variant="title-medium"
          color="highlight-default"
        >
          Dormitórios
        </Typography>
        <v-chip-group
          v-model="filter.bedrooms"
          column
        >
          <v-chip
            v-for="amount in checkbox_options"
            :key="amount"
            small
            :tabindex="3"
            :value="amount"
          >
            <Typography
              variant="label-medium"
              :color="filter.bedrooms === amount ? 'neutral-white' : 'highlight-default'"
            >
              {{ amount }}+
            </Typography>
          </v-chip>
        </v-chip-group>
      </div>

      <div class="property-suites search-options checkbox flex flex-column gap-2">
        <Typography
          variant="title-medium"
          color="highlight-default"
        >
          Suítes
        </Typography>
        <v-chip-group
          v-model="filter.suites"
          column
        >
          <v-chip
            v-for="amount in checkbox_options"
            :key="amount"
            :tabindex="4"
            :value="amount"
            small
          >
            <Typography
              variant="label-medium"
              :color="filter.suites === amount ? 'neutral-white' : 'highlight-default'"
            >
              {{ amount }}+
            </Typography>
          </v-chip>
        </v-chip-group>
      </div>

      <div class="property-parking-lots search-options checkbox flex flex-column gap-2">
        <Typography
          variant="title-medium"
          color="highlight-default"
        >
          Vagas
        </Typography>
        <v-chip-group
          v-model="filter.parkingLots"
          column
        >
          <v-chip
            v-for="(amount) in vagas_options"
            :key="amount"
            :tabindex="5"
            :value="amount"
            small
          >
            <Typography
              variant="label-medium"
              :color="filter.parkingLots === amount ? 'neutral-white' : 'highlight-default'"
            >
              {{ amount + (!amount ? "" : "+") }}
            </Typography>
          </v-chip>
        </v-chip-group>
      </div>

      <div class="property-characteristics search-options flex flex-column gap-2">
        <Typography
          variant="title-medium"
          color="highlight-default"
        >
          Características
        </Typography>
        <div class="flex flex-wrap align-center gap-2">
          <Button
            variant="secondaryXs"
            v-for="(attribute, x) in chars"
            :key="x"
            @click="() => setPropertyAttributes(attribute.id)"
            :class="[containsAttribute(attribute.id) && 'button-active', !containsAttribute(attribute.id) && 'button-default']"
          >
            {{ attribute.description }}
          </Button>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <div class="flex justify-between">
          <Typography
            variant="title-medium"
            color="highlight-default"
          >
            Idade
          </Typography>
          <Typography
            variant="title-medium"
            color="highlight-default"
          >
            {{ filter.propertyAge[0] }} - {{ filter.propertyAge[1] }} anos
          </Typography>
        </div>
        
        <v-range-slider
          v-model="filter.propertyAge"
          class="property-age"
          :tick-labels="ticksLabels"
          :min="0"
          :max="30"
          :step="5"
          :tick-size="0"
          track-color="#cccccc"
          track-fill-color="#5A7FED"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { formatStringToThousand } from '@/utils';
import {
  UNIT_ATTRIBUTES,
  PROPERTY_ATTRIBUTES,
  PROPERTY_TYPE,
  PROPERTY_STATUS,
} from '../../common/constants';
import AlertIcon from '../../components/icons/AlertIcon.vue';
import Button from '../../components/base/Button';
import CircleArrow from '../../components/icons/circleArrow';
import CurrencyField from '../../components/base/CurrencyField';
import Typography from '../../components/base/Typography';

export default {
  components: {
    AlertIcon,
    Button,
    CircleArrow,
    CurrencyField,
    Typography,
  },
  model: {
    prop: 'model',
    event: 'update',
  },
  props: {
    model: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      filterObject: {
        propertyType: PROPERTY_TYPE.APARTMENT,
        unitAttributes: [],
        propertyAttributes: [],
        propertyStatus: [],
        suites: undefined,
        parkingLots: undefined,
        bedrooms: undefined,
        areaRange: [
          null,
          null,
        ],
        propertyAge: [
          0,
          30,
        ],
        priceMin: undefined,
        priceMax: undefined,
      },
      PROPERTY_TYPE,
      UNIT_ATTRIBUTES,
      PROPERTY_ATTRIBUTES,
      PROPERTY_STATUS,
      showMoreFilters: false,
      filterHelpers: {
        bedrooms: null,
        suites: null,
        parkingLots: null,
        idadeModel: [],
        prices: {
          min: 0,
          max: 0,
        },
      },
      chars: [],
      checkbox_options: [
        1,
        2,
        3,
        4,
        5,
      ],
      vagas_options: [
        0,
        1,
        2,
        3,
        4,
        5,
      ],
      ticksLabels: [
        '0',
        '5',
        '10',
        '15',
        '20',
        '25',
        '30+',
      ],
      formatStringToThousand,
    };
  },
  computed: {
    filter: {
      get () {
        return this.filterObject;
      },
      set (filter) {
        this.filterObject = filter;
        this.$emit('update', filter);
      },
    },
    minMaxPriceIsConsistent () {
      if (!this.filter) return false;

      return this.filter.priceMin && this.filter.priceMax
        ? this.filter.priceMin <= this.filter.priceMax
        : true;
    },
    minMaxAreaIsConsistent () {
      if (!this.filter) return false;

      return this.filter.areaRange[0] && this.filter.areaRange[1]
        ? this.filter.areaRange[0] <= this.filter.areaRange[1]
        : true;
    },
  },
  watch: {
    model: {
      deep: true,
      handler (filter) {
        filter === null && this.resetFilters();
      },
    },
  },
  async created () {
    this.filter = this.filterObject;
    this.validatePrices('min', 0);
    this.validatePrices('max', 0);

    const formattedDomain = this.$route.name === 'MobileCondominium' ? 'buyer' : this.$route.name;

    this.chars = await this.getCharacteristics({ domain: formattedDomain });
  },
  methods: {
    ...mapActions({
      getCharacteristics (dispatch, payload) {
        return dispatch(`${this.$route.name}/getCharacteristics`, payload); 
      },
    }),
    ...mapMutations({
      searchByEnterKey (commit, payload) {
        return commit(`${this.$route.name}/SEARCH_BY_ENTER_KEY`, payload); 
      },
    }),
    validatePrices (type, price) {
      this.filterHelpers.prices[type] = this.getInteger(price);

      if (!this.filter) return;

      this.filter.priceMin = this.filterHelpers.prices.min
        ? this.filterHelpers.prices.min
        : undefined;
      this.filter.priceMax = this.filterHelpers.prices.max
        ? this.filterHelpers.prices.max
        : undefined;
    },
    resetFilters () {
      this.filterObject = {
        propertyType: PROPERTY_TYPE.APARTMENT,
        unitAttributes: [],
        propertyAttributes: [],
        propertyStatus: [],
        suites: undefined,
        parkingLots: undefined,
        bedrooms: undefined,
        areaRange: [
          null,
          null,
        ],
        propertyAge: [
          0,
          30,
        ],
        priceMin: undefined,
        priceMax: undefined,
      };
      this.filter = this.filterObject;
      this.filterHelpers = {
        bedrooms: null,
        suites: null,
        parkingLots: null,
        idadeModel: [],
        prices: {
          min: 0,
          max: 0,
        },
      };
    },
    preventNonInteger ($event) {
      if (
        ($event.target.value === '0' && $event.key === '0')
        || ($event.key && $event.key.match(/[/\-+.,*]/))
        || ($event.key
          && $event.key.match(/\D/)
          && !(
            $event.key === 'Delete'
            || $event.key === 'Backspace'
            || $event.key === 'Tab'
            || $event.key === 'ArrowLeft'
            || $event.key === 'ArrowRight'
          ))
      ) {
        return $event.preventDefault();
      }

      return null;
    },
    getInteger (value) {
      const int = parseInt(`${value}`.replace(/\D/g, ''), 10) || 0;

      if (int < 0) return 0;

      return int;
    },
    isEmpty (filterUsed) {
      return (filterUsed === '' || filterUsed === undefined)
    },
    containsStatus (phase) {
      return this.filter.propertyStatus.find(propStatus => propStatus.value === phase.value)
    },
    containsAttribute (attribute) {
      return this.filter.propertyAttributes.find(propAttribute => propAttribute === attribute)
    },
    setPropertyStatus (phase) {

      if(this.containsStatus(phase)){
        return this.filter.propertyStatus = this.filter.propertyStatus.filter(propStatus => propStatus.value !== phase.value)
      }
      this.filter.propertyStatus = [phase, ...this.filter.propertyStatus]
    },
    togglePropertyStatus(statusValue) {
      const index = this.filter.propertyStatus.indexOf(statusValue);
      if (index === -1) {
        this.filter.propertyStatus.push(statusValue);
      } else {
        this.filter.propertyStatus.splice(index, 1);
      }
    },
    setPropertyAttributes (attribute) {
      if(this.containsAttribute(attribute)){
        return this.filter.propertyAttributes = this.filter.propertyAttributes.filter(propAttribute => propAttribute !== attribute)
      }
      this.filter.propertyAttributes = [attribute, ...this.filter.propertyAttributes]
    }
  },
};
</script>

<style lang="scss" scoped>
.width-other-filters {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 99%
}

.filters-label {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 13px;
  color: #4663b9;

  &.narrow {
    font-weight: 400;
  }
}

.property-area, .property-price {
  :deep(.v-text-field--outlined) {
    border-radius: 8px;
    background: var(--colors-highlight-lighter);
    height: 36px;
  }
  :deep(.v-text-field--outlined .v-label) {
    top: 7px;
  }
  :deep(.v-text-field--outlined .v-label.v-label--active) {
    top: 16px;
  }
  :deep(.v-label){
    color: var(--colors-neutral-grey-5)!important;
  }
  :deep(.v-label.v-label--active){
    color: var(--colors-highlight-lighter);
  }
  :deep(.v-input__control fieldset){
    height: 40px;
  }
  :deep(.v-text-field__slot) {
    height: 34px;
  }
  :deep(.v-input__slot) {
    max-height: 34px!important;
  }
}

.property-age {
  ::v-deep .v-slider .v-slider__thumb-container .primary.v-slider__thumb {
    background-color: #ffffff !important;
    border: 1px solid #58669380 !important;
    border-radius: 2px !important;
    width: 8px;
    height: 18px;
    left: -1px;

    &::before {
      opacity: 0 !important;
    }
  }

  ::v-deep .v-slider .v-slider__thumb-container:last-of-type .primary.v-slider__thumb {
    background-color: #ffffff !important;
    border: 1px solid #58669380 !important;
    border-radius: 2px !important;
    width: 8px;
    height: 18px;
    left: -8px!important;

    &::before {
      opacity: 0 !important;
    }
  }

  :deep(.v-slider--horizontal){
    margin: 0!important;
  }

  ::v-deep .v-slider__tick-label {
    margin-top: 8px;
    color: var(--colors-highlight-default);
    font-family: Lato;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      width: 4px;
      height: 4px;
      background-color: #c4c4c4;
      border-radius: 50%;
      transform: translateX(calc(500% - 4px));
    }
  }

  ::v-deep .v-slider__ticks-container {
    span:last-of-type {
      ::after {
        opacity: 0;
      }
    }
  }
}

.value-error {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #e74c3c;
}
.more-filters {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
.more-filters-separator {
  flex-grow: 1;
  height: 1px;
  background: var(--colors-highlight-default);
}
.additional-filters {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .search-input-mobile {
    ::v-deep .v-input {
      display: flex;
      height: 38px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      background-color: #7e92ce1a !important;

      &:not(.v-input--is-focused):not(.v-input--has-state) {
        fieldset {
          color: #e8e8e8;
        }
      }

      fieldset {
        border-width: 1px !important;
      }
      input {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        color: var(--colors-brand-dark)!important;
      }

      input::placeholder {
        color: #777777!important;
      }

      .v-text-field__prefix, .v-text-field__suffix {
        color: var(--colors-neutral-grey-5);
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
      }
      &__slot {
        padding: 12px 0px;
        height: 32px !important;
        min-height: auto !important;
      }
    }

    :deep(.v-input.v-input--is-focused .v-text-field__prefix, .v-text-field__suffix){
      color: var(--colors-brand-dark)!important;
    }
  }

  .search-options {
    :deep(.v-slide-group__content) {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0;
    }
    ::v-deep .v-chip {
      background-color: transparent;
      box-shadow: none;
      margin: 0;
      &--active {
        background-color: var(--colors-highlight-default);
        &:before {
          opacity: 0;
          background-color: transparent;
        }
      }
    }
    &.checkbox {
      ::v-deep .v-chip {
        border-radius: 0.25rem;
        padding: 0;
        text-align: center;
        font-size: 13px;
        min-width: 25px;
        min-height: 25px;
        &__content {
          margin: auto;
          height: auto;
        }
      }
    }
  }
}

.property-type {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.button-active {
  color: var(--colors-neutral-white)!important;
  border-color: 1px solid var(--colors-highlight-default)!important;
  background-color: var(--colors-highlight-default)!important;
}
.button-default {
  color: var(--colors-highlight-light)!important;
  background-color: var(--colors-neutral-white)!important;
}
</style>
