<template>
  <div
    ref="wrapper"
    class="custom-input"
  >
    <div class="custom-input__content-wrapper">
      <input
        :id="name"
        ref="input"
        data-single-search
        :class="{
          'input-field': true,
          'input-field--filled': isFilled,
          'input-field--error': isError,
        }"
        :type="type"
        :placeholder="inputPlaceholder"
        autocomplete="off"
        :disabled="disabled"
        :value="inputValue"
        :readonly="Boolean(multiple)"
        @focus="onFocus"
        @input="onInput"
      >

      <label
        :for="name"
        :class="{
          'label': true,
          'label--filled': isFilled,
          'label--active': active,
          'label--error': isError,
        }"
      >
        {{ label }}
      </label>

      <div
        v-show="active"
        ref="dropdown"
        class="options-list"
      >
        <template v-if="multiple">
          <input
            v-model="search"
            data-multiple-search
            class="search"
            placeholder="Digite para filtrar..."
            @focus="onFocus"
          >

          <span class="selected-title">Selecionado(s):</span>
          
          <RecycleScroller
            emit-update
            class="scroller scroller--selected"
            :items="selected"
            :item-size="customItemHeight"
            key-field="id"
            @update="onScrollerUpdate"
          >
            <template
              v-if="!selected.length"
              #before
            >
              <div class="scroller-item">
                <CheckboxCheckedIcon />
                <span>Todas</span>
              </div>
            </template>

            <template #default="{ item }">
              <div
                class="scroller-item"
                @click="() => onSelect(item)"
              >
                <CheckboxCheckedIcon />
                <span>{{ multiple ? item[multiple.label] : item }}</span>
              </div>
            </template>
          </RecycleScroller>
        </template>
        
        <div class="filtered-list">
          <RecycleScroller
            v-show="!loading"
            emit-update
            :class="{
              'scroller': true,
              'scroller--multiple': multiple,
            }"
            :items="filtered"
            :item-size="customItemHeight"
            key-field="id"
            @update="onScrollerUpdate"
          >
            <template
              v-if="!filtered.length"
              #before
            >
              <div class="scroller-item">
                <template v-if="loading">
                  Carregando resultados...
                </template>
                
                <template v-else>
                  Nenhum resultado encontrado.
                </template>
              </div>
            </template>

            <template #default="{ item }">
              <div
                class="scroller-item"
                :style="{
                  height: `${customItemHeight}px`,
                }"
                @click="() => onSelect(item)"
              >
                <slot
                  v-if="customItemSlot"
                  :item="item"
                />

                <template v-else>
                  <CheckboxUncheckedIcon v-if="multiple" />

                  <span v-if="multiple">{{ item[multiple.label] }}</span>
                  <span v-else-if="single">{{ item[single.label] }}</span>
                  <span v-else>{{ item }}</span>
                </template>
              </div>
            </template>
          </RecycleScroller>
        </div>

        <div
          v-if="multiple"
          class="footer"
        >
          <template v-if="loading">
            Carregando resultados...
          </template>
          
          <template v-else>
            {{ formatter.format(filtered.length) }} resultado(s) disponívei(s).
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="isError"
      class="custom-input__error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>

import debounce from 'lodash.debounce';

/**
 * vue-virtual-scroller
 */
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { RecycleScroller } from 'vue-virtual-scroller';

/**
 * Icons
 */
import CheckboxUncheckedIcon from '@/components/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '@/components/icons/CheckboxCheckedIcon';

/**
 * Utils
 */
import toggleStringSequence from '@/utils/toggleStringSequence';
import stringToSlug from '@/utils/stringToSlug';

/**
 * Component
 */
export default {

  name: 'Autocomplete',

  components: {
    RecycleScroller,
    CheckboxUncheckedIcon,
    CheckboxCheckedIcon,
  },

  props: {

    value: {
      type: [
        String,
        Object,
      ],
      required: false,
      default: '',
    },

    single: {
      type: Object,
      default: null,
    },
    
    multiple: {
      type: Object,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },
    
    options: {
      type: Array,
      required: true,
      default: () => ([]),
    },

    remote: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      required: true,
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      required: false,
      default: '',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    customItemSlot: {
      type: Boolean,
      default: false,
    },

    customItemHeight: {
      type: Number,
      default: 39,
    },
  },

  data () {

    return {
      active: false,
      search: '',
      selectedItems: '',
      blurWhitelist: [],
      results: [],
      groupIds: []
    };
  },

  computed: {

    inputValue () {

      if (this.single) {

        if (typeof this.innerVal === 'object') {

          return this.innerVal[this.single.label];
        }
        else {

          return this.innerVal;
        }
      }
      else if (this.multiple) {

        return !this.selectedIds.length ? 'Todas' : `${this.groupIds.length} selecionada(s)`;
      }
      
      return this.innerVal;
    },

    inputPlaceholder () {
      return this.active ? this.placeholder : '';
    },

    isError () {
      return this.error;
    },

    isFilled () {
      return Boolean(this.value);
    },

    innerVal: {

      get () {
        return this.value;
      },

      set (val) {
        this.$emit('input', val);
      },
    },

    filtered () {
      
      const results = this.options.filter(item => {

        const selected = this.selectedIds.indexOf(`${item.id}`) !== -1;

        if (!this.remote) {

          const itemSlug = stringToSlug(this.multiple ? item[this.multiple.label] : item);
          const valueSlug = stringToSlug(this.multiple ? this.search : this.value);

          const found = stringToSlug(itemSlug).indexOf(valueSlug) !== -1;

          return !selected && found;
        }

        return !selected;
      });

      const isNotObjectFirstItem = typeof results[0] !== 'object' && results.length;

      if(!isNotObjectFirstItem) {
        this.results = this.groupedByName(results);

        return this.results;
      }

      return results;
    },

    selected () {
      const selectedSet = new Set(this.selectedIds);
      const filteredIds = this.options.filter(item => selectedSet.has(`${item.id}`));
      const grouped = this.groupedByName(filteredIds);

      return grouped.filter(item => 
        item.group.some(groupItem => selectedSet.has(`${groupItem.id}`))
      );
    },

    selectedIds () {
      return this.selectedItems
        .split(',')
        .filter(attribute => Boolean(attribute));
    },
  },
  
  watch: {

    selectedItems (newValue) {

      this.$emit('input', newValue);
    },

    value (newValue) {

      if (this.multiple) {
        this.selectedItems = newValue;
      }
    },

    search: debounce(function (newValue) {

      this.$emit('search-input', newValue);
    }, 500),
  },

  created () {

    this.formatter = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      minimumFractionDigits: 0,
    });
  },

  mounted () {

    document.addEventListener('click', this.onClickOutside);

    this.$nextTick(() => this.blurWhitelist = this.generateBlurWhitelist());
  },

  beforeUnmount () {

    document.removeEventListener('click', this.onClickOutside);
  },

  methods: {

    generateBlurWhitelist () {

      const whitelist = [];

      whitelist.push(this.$refs.input);
      whitelist.push(this.$refs.dropdown);

      const children = this.$refs.wrapper.querySelectorAll('*');

      for (const child of children) {
        whitelist.push(child);
      }

      return whitelist;
    },

    onScrollerUpdate () {

      this.blurWhitelist = this.generateBlurWhitelist();
    },
    
    onClickOutside (event) {

      if (this.blurWhitelist.indexOf(event.target) < 0) {
        this.active = false;
      }
    },

    onInput (event) {

      const { target } = event;
      
      if (!this.multiple) {
        this.innerVal = target.value;

        if (this.single) {
          this.search = target.value;
        }
      }
    },

    onFocus (event) {

      const { target } = event;

      if (target) {
        
        const isSingleSearch = !this.multiple && target.hasAttribute('data-single-search');
        const isMultipleSearch = this.multiple && target.hasAttribute('data-multiple-search');

        if (isSingleSearch
          || isMultipleSearch) {

          target.setSelectionRange(0, target.value.length);
        }
      }

      if (!this.active
        && !this.loading) {

        this.active = true;
      }
    },

    onSelect (value) {

      if (this.single) {
        this.innerVal = value;
        this.$emit('select', value);
      }
      else if (this.multiple) {
        const groupSet = new Set(value.group.map(item => item.id));

        const existingSetIndex = this.groupIds.findIndex(existingSet => 
          existingSet.size === groupSet.size && // valida se o tamanho é igual
          [...existingSet].every(id => groupSet.has(id)) // valida se todos os elementos são iguais
        );

        existingSetIndex === -1 ? this.groupIds.push(groupSet) : this.groupIds.splice(existingSetIndex, 1);
        
        this.selectedItems = toggleStringSequence([...groupSet], this.selectedItems);
      }
      else {
        this.innerVal = value;
        this.$emit('select', value);
      }

      if (!this.multiple) {
        this.active = false;
      }
    },

    groupedByName (result) {
      return Object.entries(result.reduce((acc, { name, ...rest }) => {
        if (!acc[name]) {
          acc[name] = [];
        }
        acc[name].push({ name, ...rest });
        return acc;
      }, {}))
      .map(([key, value]) => ({
        id: value[0].id,
        name: key,
        type: value[0].type,
        group: value
      }));
    }
  },
};

</script>

<style lang="scss" scoped>

.custom-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  height: 40px;
  margin-bottom: 4px;

  &__error-message {
    font-size: 12px;
    line-height: 12px;
    color: #B75757;
    margin-left: 8px;
  }

  &__content-wrapper {
    position: relative;
  }
  .input-field {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background: rgba(126, 146, 206, 0.1);
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 18.5px 14px;
    height: 40px;

    font-size: 16px;
    line-height: 19px;
    color: #707070;
    text-align: left;

    transition: box-shadow .2s ease-out;

    &::placeholder {
      color: #707070;
      opacity: 1;
    }

    &:focus {
      outline: none;
      background: rgba(126, 146, 206, 0.1);
      box-shadow: inset 0 0 0 2px #4663B9;
      transition: box-shadow .2s ease-out;

      & ~ .label {
        top: -6px;
        font-size: 12px;
        line-height: 12px;
        color: #4663B9;
        transition: all .2s ease-out;
        background-color: #FFFFFF;
      }
    }

    &--filled {
      color: #303A56;
      border-color: #E8E8E8;
      transition: box-shadow .2s ease-out;

      & ~ .label {
        top: -6px;
        color: #4663B9;
        font-size: 12px;
        line-height: 12px;
        background-color: #FFFFFF;
      }
    }

    &--error {
      box-shadow: inset 0 0 0 2px #B75757;
      transition: box-shadow .2s ease-out;
      background-color: #FFFFFF;

      & ~ .label {
        top: -6px;
        font-size: 12px;
        line-height: 12px;
        color: #B75757;
        background-color: #FFFFFF;
      }
    }

    &:disabled {
      background: #F5F5F5;
      border: 1px solid #E8E8E8;
      color: #CCCCCC;
      box-shadow: none;

      & ~ .label {
        top: -6px;
        font-size: 12px;
        line-height: 12px;
        color: #CCCCCC;
        background-color: #FFFFFF;
      }
    }
  }
  .label {
    position: absolute;
    top: 10px;
    left: 14px;
    font-size: 16px;
    line-height: 19px;
    color: #707070;
    border-radius: 3px;
    padding: 0 3px;
    background-color: #f2f4fa;
    transition: all .2s ease-out;
    z-index: 10;
  }

  .search {
    color: #707070;
    width: 100%;
    border-bottom: 1px solid #DDD;
    padding: 10px 14px;
  }

  .footer {
    color: #A2A5AB;
    font-size: 14px;
    font-family: 'Lato';
    padding: 3px 14px 12px;
  }

  .selected-title {
    display: inline-block;
    color: #A2A5AB;
    padding: 10px 14px 5px;
    font-weight: 700;
    font-size: 13px;
  }

  .options-list {
    position: absolute;
    top: 44px;
    z-index: 100;
    width: 100%;
    height: fit-content;
    background-color: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    .scroller {
      max-height: 192px;
      height: 100%;

      &--multiple {
        border-top: 1px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
        margin-bottom: 8px;
      }
    }

    .scroller-item {
      padding: 8px 14px;
      color: #555555;
      text-decoration: none;
      cursor: pointer;
      transition: background-color .25s;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;

      > span {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
      }

      &:hover {
        background-color: #E3E8F5;
      }
    }
  }
}

</style>
